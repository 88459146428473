import React from "react"
import Logo from "../../../static/icons/Inditech-Labs-Logo.png"


const Navbar = ({ page }) => {
  return (
    <nav className="navbar">
      <Tabs page={ page } />
    </nav>
  )
}

const Tabs = ({ page }) => {
  // console.log(page)
  // function customScroll(event) {
  //     let to = event.target.attributes.getNamedItem("to").value
  //     let height = document.getElementsByClassName("header")[0].getBoundingClientRect().height
  //     if(to == "productHome" || to == "home") height = 0
  //     window.scrollTo(0, document.getElementById(to).getBoundingClientRect().top - height)
  //     console.log(event.target.attributes.getNamedItem("to").value)
  // }
  if (page == "") {
    return (
      <ul className="tabs">
        <li className="tab active">
          <a href="/#home">HOME</a>
        </li>
        <li className="tab">
          <a href="/#products">PRODUCTS</a>
        </li>
        <li className="tab">
          <a href="/#about">ABOUT US</a>
        </li>
        {/*<li className="tab">
          <a href="#locations">LOCATIONS</a>
        </li>*/}
        {/* <li className="tab"><a href="#">CAREER</a></li> */}
        <li className="tab">
          <a href="/#contact">CONTACT US</a>
        </li>
        {/*<li className="tab">
          <a href="#blog">BLOG</a>
        </li>*/}
        <li className="tab">
          <a href="tel:+917984489771" className="phone">
            +91-7984489771
          </a>
        </li>
      </ul>
    )
  } else if (page == "products") {
    return (
      <ul className="tabs">
        {/* <li className="tab active"><a to="productHome" href="#" onClick={ (event) => customScroll(event) }>HOME</a></li> */}
        <li className="tab active">
          <a href="#productHome">HOME</a>
        </li>
        <li className="tab">
          <a href="#features">FEATURES</a>
        </li>
        <li className="tab">
          <a href="#testimonials">TESTIMONIALS</a>
        </li>
        {/*<li className="tab">
          <a href="#locations">LOCATIONS</a>
        </li>*/}
        <li className="tab">
          <a href="#gallery">GALLERY</a>
        </li>
        <li className="tab">
          <a href="#faqs">FAQ'S</a>
        </li>
      </ul>
    )
  }
}

export default Navbar
